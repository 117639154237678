import { useEffect, useState } from "react"
import "./ViewSwitcher.css"
import classNames from "classnames"
import { useTheme } from "ThemeContext"

const ViewSwitcher = ({ tabNames, pageSwitcher, defaultView }) => {
  const [activeTab, setActiveTab] = useState(tabNames[0])
  useEffect(() => {
    if (defaultView) setActiveTab(defaultView)
  }, [defaultView])

  const ViewSwitcherDropDown = () => {
    return (
      <select className="view-switcher-container-dropdown">
        {tabNames.map((tabName, index) => (
          <option
            key={index}
            className="switcher-tab option"
            onClick={() => setActiveTab(tabName)}
          >
            {tabName}
          </option>
        ))}
      </select>
    )
  }

  return (
    <>
      <div
        className={classNames("view-switcher-container", {
          "dark-mode": useTheme(),
          "page-switcher": pageSwitcher,
        })}
      >
        {tabNames.map((tabName, index) => (
          <div
            key={index}
            className={classNames("switcher-tab", {
              "is-active": activeTab === tabName,
            })}
            onClick={() => setActiveTab(tabName)}
          >
            {tabName}
          </div>
        ))}
      </div>
      <div className="page-switcher-dropdown">{ViewSwitcherDropDown()}</div>
    </>
  )
}

export default ViewSwitcher
