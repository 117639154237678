import { useState, createContext, useContext } from "react"

const ThemeContext = createContext()
const UpdateThemeContext = createContext()

// global state
export function useTheme() {
  return useContext(ThemeContext)
}

// global setState
export function useUpdateTheme() {
  return useContext(UpdateThemeContext)
}

const ThemeMode = ({ children }) => {
  const [darkTheme, setDarkTheme] = useState(false)
  const toggleTheme = () => setDarkTheme((prevTheme) => !prevTheme)

  return (
    <ThemeContext.Provider value={darkTheme}>
      <UpdateThemeContext.Provider value={toggleTheme}>
        {children}
      </UpdateThemeContext.Provider>
    </ThemeContext.Provider>
  )
}

export default ThemeMode
