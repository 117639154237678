import React, { useState, useEffect } from "react"
import PriceBox from "./PriceBox"
import Marquee from "react-fast-marquee"
import { fetchPrice } from "api/price"
import { useTheme } from "ThemeContext"

import "./NavScrollTab.css"
import classNames from "classnames"

const NavScrollTab = () => {
  const [data, setData] = useState([])

  const fetchData = async () => {
    const { data: priceData } = await fetchPrice()
    setData(priceData)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const navSlideStyle = classNames("nav-slider-wrapper", {
    "dark-mode": useTheme(),
  })
  return (
    <div className={navSlideStyle}>
      <Marquee gradient={false} speed={60} className="nav-scroll-slider">
        {data && data.map((item, index) => <PriceBox item={item} key={index} />)}
      </Marquee>
    </div>
  )
}

export default NavScrollTab
