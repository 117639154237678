import classNames from "classnames"
import Option from "components/elements/Option/Option"
import PriceTrackerTable from "components/elements/PriceTrackerTable/PriceTrackerTable"
import { useTheme } from "ThemeContext"
import "./PriceTracker.css"

//state for view
const PriceTracker = ({ type, defaultView }) => {
  return (
    <div
      className={classNames("price-tracker-container", {
        "dark-mode": useTheme(),
      })}
    >
      <Option type={type} defaultView={defaultView} />
      <PriceTrackerTable type={type} />
    </div>
  )
}

export default PriceTracker
