import React from "react"
import { createRoot } from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./fonts/Euclid-Circular-B-Bold-Italic.ttf"
import "./fonts/Euclid-Circular-B-Bold.ttf"
import "./fonts/Euclid-Circular-B-Italic.ttf"
import "./fonts/Euclid-Circular-B-Light-Italic.ttf"
import "./fonts/Euclid-Circular-B-Light.ttf"
import "./fonts/Euclid-Circular-B-Medium-Italic.ttf"
import "./fonts/Euclid-Circular-B-Medium.ttf"
import "./fonts/Euclid-Circular-B-Regular.ttf"
import "./fonts/Euclid-Circular-B-SemiBold-Italic.ttf"
import "./fonts/Euclid-Circular-B-SemiBold.ttf"
import ThemeMode from "./ThemeContext"

const rootElement = document.getElementById("root")
const root = createRoot(rootElement)

root.render(
  <ThemeMode>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
