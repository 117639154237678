import React from "react"

import "./PriceBox.css"

const PriceBox = ({ item }) => {
  const { currencyFrom, currencyTo, currentPrice, openingPrice } = item

  return (
    <div className="nav-slider-price-box">
      <div className="nav-slider-currency-pair">
        <p className="nav-slider-pair-text">{`${currencyFrom}/ ${currencyTo}`}</p>
        <span
          className={`nav-slider-arrow-${
            currentPrice > openingPrice ? "up" : "down"
          }`}
        ></span>
      </div>
      <p className="nav-slider-price">{currentPrice}</p>
    </div>
  )
}

export default PriceBox
