import Navbar from "./components/elements/Navbar/Navbar"
import classNames from "classnames"
import { useTheme } from "ThemeContext"
import PriceTracker from "components/PriceTracker/PriceTracker"
import "./App.css"
import Footer from "./components/elements/Footer/Footer"

function App() {
  const appStyle = classNames("App", { "dark-mode": useTheme() })
  return (
    <div className={appStyle}>
      <div className="app-frame">
        <Navbar />
        <main className="content-container">
          <section className="price-trackers-wrapper">
            <PriceTracker type="döviz" defaultView="Döviz" />
            <PriceTracker type="sarrafi" defaultView="Sarrafiye" />
          </section>
        </main>
        <footer className="footer-wrapper">
          <Footer />
        </footer>
      </div>
    </div>
  )
}

export default App
