import { useState, useEffect } from "react"
import classNames from "classnames"
import ViewSwitcher from "../ViewSwitcher/ViewSwitcher"
import NavScrollTab from "../NavScrollTab/NavScrollTab"
import { useTheme, useUpdateTheme } from "ThemeContext"

import OzakLogo from "../../../images/ozak-logo.svg"
import OzakLogoDark from "../../../images/ozak-logo-dark.svg"
import MoonIcon from "../../../images/moon-icon.svg"
import SunIcon from "../../../images/sun-icon.svg"
import CalendarIcon from "../../../images/calendar-icon.svg"
import StopwatchIcon from "../../../images/stopwatch-icon.svg"
import "./Navbar.css"

const Navbar = () => {
  const [time, setTime] = useState(new Date())
  useEffect(() => {
    setInterval(() => setTime(new Date()), 1000)
    return () => {
      clearInterval()
    }
  }, [])

  const navTheme = classNames("nav-wrapper", { "dark-mode": useTheme() })
  return (
    <div className={navTheme}>
      <NavScrollTab />
      <nav className="navbar-container">
        <img
          src={useTheme() ? OzakLogoDark : OzakLogo}
          className="ozak-logo"
          alt="Ozak Logo"
        />
        <div className="nav-items-wrapper">
          <div className="nav-items-group-left">
            <ViewSwitcher tabNames={["Döviz", "Altın", "Sarrafiye"]} />
            <div className="nav-items-container">
              <div className="nav-input-container">
                <label className="label">ALTIN SEÇİN</label>
                <div className="dropdown-container">
                  <select name="altin-select" className="select">
                    <option value="1" className="altin-option">
                      0.5gr (22)
                    </option>
                    <option value="21" className="altin-option">
                      0.5gr (22)
                    </option>
                    <option value="3" className="altin-option">
                      0.5gr (22)
                    </option>
                  </select>
                </div>
              </div>
              <div className="nav-input-container">
                <label className="label">MİKTAR GİRİN</label>
                <div className="search-container">
                  <input type="text" className="search-input" placeholder="1" />
                </div>
              </div>
              <div className="nav-input-container">
                <label className="label">PARİTE SEÇİN</label>
                <div className="dropdown-container">
                  <select name="altin-select" className="select">
                    <option value="1">1</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="nav-items-container">
              <div className="price-container">
                <label className="label">ALIŞ</label>
                <p>2543.24</p>
              </div>
              <div className="price-container">
                <label className="label">SATIŞ</label>
                <p>2543.24</p>
              </div>
            </div>
          </div>
          <div className="nav-items-group-right">
            <ViewSwitcher
              tabNames={["Anasayfa", "Toptan", "Perakende", "İletişim"]}
              pageSwitcher
            />
            <img
              src={useTheme() ? SunIcon : MoonIcon}
              className="theme-icon"
              alt="Dark Mode Switch"
              onClick={useUpdateTheme()}
            />
            <div className="date-time-container">
              <div className="date-container">
                <img
                  className="calendar-icon"
                  src={CalendarIcon}
                  alt="Calendar Icon"
                />
                <p className="date-text">{time.toLocaleDateString("tr")}</p>
              </div>
              <div className="time-container">
                <img
                  className="clock-icon"
                  src={StopwatchIcon}
                  alt="Calendar Icon"
                />
                <p className="date-text">{time.toLocaleTimeString("tr")}</p>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
