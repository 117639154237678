import React, { useRef, useEffect } from "react"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import "./Mapbox.css"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const Mapbox = () => {
  const mapContainerRef = useRef(null)
  const defaultMarker = {
    longitude: 28.815328077041404,
    latitude: 41.004297885252896,
  }

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: process.env.REACT_APP_MAPBOX_STYLE,
      center: [defaultMarker.longitude, defaultMarker.latitude],
      zoom: 10,
      attributionControl: false,
    })

    new mapboxgl.Marker()
      .setLngLat([defaultMarker.longitude, defaultMarker.latitude])
      .addTo(map)

    // Clean up on unmount
    return () => map.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className="mapbox-container" ref={mapContainerRef} />
    </div>
  )
}

export default Mapbox
