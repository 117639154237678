import { useState, useEffect } from "react"
import { useTheme } from "ThemeContext.js"
import classNames from "classnames"

import ViewSwitcher from "../ViewSwitcher/ViewSwitcher.js"
import CoinStackIcon from "images/coint_stack-icon.svg"
import DollarIcon from "images/dollar-icon.svg"
import "./Option.css"

const Option = ({ type: optiontype, defaultView, subtitle }) => {
  const [viewSwitcherOptions, setViewSwitcherOptions] = useState([])
  const [title, setTitle] = useState("")

  const isDoviz = (str) => str === "döviz"
  const isSarrafi = (str) => str === "sarrafi"
  useEffect(() => {
    if (isDoviz(optiontype)) {
      setViewSwitcherOptions(["Genel", "Döviz", "Parite"])
      setTitle("Döviz Fiyatları")
    }
    if (isSarrafi(optiontype)) {
      setViewSwitcherOptions(["Sarrafiye", "Gram Altın"])
      setTitle("Sarrafiye")
    }
  }, [optiontype])

  const optionTheme = classNames("price-tracker-container", {
    "dark-mode": useTheme(),
  })

  const chooseIcon = (reference) => {
    if (isDoviz(reference))
      return <img src={DollarIcon} className="option-icon" alt="option icon" />

    isSarrafi(reference)
    return <img src={CoinStackIcon} className="option-icon" alt="option icon" />
  }

  return (
    <main className={optionTheme}>
      <section className="option-container">
        <div className="option-format">
          {chooseIcon(optiontype)}
          <div>
            <h4 className="option-title">{title}</h4>
            <p className="option-subtitle">
              {
                // prettier-ignore
                subtitle.split(" ").map((word, i) =>
                word.includes(".com")
                   ? <span className="option-url" key={i}> {word} </span>
                   : `${word} `
              )
              }
            </p>
          </div>
        </div>
        <ViewSwitcher tabNames={viewSwitcherOptions} defaultView={defaultView} />
      </section>
    </main>
  )
}

Option.defaultProps = {
  subtitle: " Piyasa verileri Foreks.com tarafından sağlanmaktadır.",
}
export default Option

/* */
