import classNames from "classnames"
import { useTheme } from "ThemeContext"
import OzakLogo from "images/ozak-logo.svg"
import OzakLogoDark from "images/ozak-logo-dark.svg"
import MapIcon from "images/map-icon.svg"
import PhoneIcon from "images/phone-icon.svg"
import MailIcon from "images/mail-icon.svg"
import MapIconDark from "images/map-dark.svg"
import PhoneIconDark from "images/phone-dark.svg"
import MailIconDark from "images/mail-dark.svg"
import AppleIcon from "images/apple-icon.svg"
import PlayStoreIcon from "images/play-store-icon.svg"
import FooterImage from "images/footer-image.svg"

import "./Footer.css"
import SocialIconsBox from "../SocialIconsBox/SocialIconsBox"
import Mapbox from "../Mapbox/Mapbox"

const FooterContact = ({ icon, contactDetail }) => (
  <div className="footer-contact-detail">
    <img src={icon} className="contact-detail-icon" alt="footer icon" />
    <p className="contact-detail-text">{contactDetail}</p>
  </div>
)

const Footer = () => {
  const footerStyle = classNames("footer-container", { "dark-mode": useTheme() })

  return (
    <div className={footerStyle}>
      <section className="contact-info-wrapper">
        <div className="footer-logo">
          <img
            src={useTheme() ? OzakLogoDark : OzakLogo}
            className="footer-ozak-logo"
            alt="ozak logo"
          />
        </div>
        <div className="contact-info-container">
          <FooterContact
            icon={useTheme() ? MapIconDark : MapIcon}
            contactDetail={
              "Tayahatun Mah. Acı Çeşme Sok. No:7-11 İç Kapı No:5 Fatih İSTANBUL"
            }
          />
          <FooterContact
            icon={useTheme() ? MailIconDark : MailIcon}
            contactDetail={"info@ozakkiymetlimadenler.com"}
          />
          <FooterContact
            icon={useTheme() ? PhoneIconDark : PhoneIcon}
            contactDetail={"0 212 513 1399"}
          />
        </div>
        <SocialIconsBox />
      </section>
      <section className="advert-mapbox-section-wrapper">
        <div className="mobile-app-links-container">
          <div className="description-text">
            <p className="title-text">Özak Kıymetli Madenler</p>
            <p className=" title-text semi-bold">Şimdi Cebinizde!</p>
            <p className="subtitle-text">
              Piyasa verileri <span className="highlighted">Foreks.com</span>{" "}
              tarafından sağlanmaktadır.
            </p>
          </div>
          <div className="footer-mobile-app-image-container">
            <img className="icon" src={FooterImage} alt="Özak Mobil Uygalaması" />
          </div>
          <div className="download-buttons-container">
            <a
              href="https://apps.apple.com/us/app/ozak-kiymetli-madenler/id1498797576"
              target="_blank"
              rel="noopener noreferrer"
              className="download-button"
            >
              <img src={AppleIcon} alt="app store" />
              <div className="download-app-text-container">
                <p className="download-app-text">Şimdi indirin</p>
                <p className="download-app-text semi-bold">App Store</p>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ozakkiymetlimadenler"
              target="_blank"
              rel="noopener noreferrer"
              className="download-button"
            >
              <img src={PlayStoreIcon} alt="google play" />{" "}
              <div className="download-app-text-container">
                <p className="download-app-text">Şimdi indirin</p>
                <p className="download-app-text semi-bold">Google Play</p>
              </div>
            </a>
          </div>
        </div>
        <div className="mapbox-wrapper">
          <Mapbox />
        </div>
      </section>
      <section className="copyright-section-wrapper">
        <p className="copyright-section-text">
          &copy; Copyright 2022 Ozakkiymetlimadenler.com - All Rights Reserved
        </p>
        <p className="copyright-section-text highlighted">
          Bilgi Toplumu Hizmetleri
        </p>
      </section>
    </div>
  )
}

export default Footer
