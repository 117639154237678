import { useState, useEffect } from "react"
import ClockIcon from "images/clock-icon.svg"
import ClockIconDark from "images/clock-icon-dark.svg"
import GoldCoinIcon from "images/gold-coin.svg"
import classNames from "classnames"
import dummy from "utils/dummy.json"
import "./PriceTrackerTable.css"
import { useTheme } from "ThemeContext"

//for the sake of showing the dummy data

const TableRow2 = () => {
  return (
    <div className="table-row">
      <p className="table-cell sembol">
        <img src={GoldCoinIcon} alt="gold" className="icon" />
        Çeyrek
      </p>
      <div className="table-cell price">
        <p className="figure">1.63</p>
        <p className="figure highlighted red">1458.013</p>
      </div>
      <div className="table-cell price">
        <p className="figure">1.633</p>
        <p className="figure highlighted green">1458.013</p>
      </div>
      <div className="table-cell price">
        <p className="figure">1.61</p>
        <p className="figure highlighted red">1458.013</p>
      </div>
      <div className="table-cell price">
        <p className="figure">1.626</p>
        <p className="figure highlighted green">1458.013</p>
      </div>
      <p className="table-cell time">
        <img
          src={useTheme() ? ClockIconDark : ClockIcon}
          alt="time"
          className="clock-icon"
        />
        17:00
      </p>
    </div>
  )
}

const Price = ({ oldPrice, newPrice }) => {
  const [priceChange, setPriceChange] = useState(0)
  useEffect(() => {
    setPriceChange(newPrice - oldPrice)
  }, [oldPrice, newPrice])

  return (
    <>
      <div
        className={classNames("figure", {
          yellow: priceChange === 0,
          red: priceChange < 0,
          green: priceChange > 0,
        })}
      >
        <p>{newPrice}</p>
        {priceChange > 0 && <span className="up-arrow" />}
        {priceChange < 0 && <span className="down-arrow" />}
        {priceChange === 0 && <span className="equal">&#8722;</span>}
      </div>
    </>
  )
}

const PriceTrackerTable = ({ type }) => {
  const [time, setTime] = useState(new Date())
  useEffect(() => {
    setInterval(() => setTime(new Date()), 1000)
    return () => {
      clearInterval()
    }
  }, [])

  const usetheme = useTheme()

  return (
    <div
      className={classNames("price-tracker-table-container", {
        "dark-mode": useTheme(),
      })}
    >
      {type === "döviz" && (
        <>
          <div className="table-heading-container">
            <p className="table-heading sembol">Sembol</p>
            <p className="table-heading">Alış</p>
            <p className="table-heading">Satış</p>
            <p className="table-heading">
              {`${time.toLocaleDateString("tr")} - ${time.toLocaleTimeString("tr", {
                hour: "2-digit",
                minute: "2-digit",
              })}`}
            </p>
          </div>
          <div className="table-body-container">
            {dummy.map((item, index) => (
              <div key={index} className="table-row">
                <div className="table-cell sembol">
                  <p className="icons">
                    <img
                      className="currency-icon"
                      src={item.pairIcons[0]}
                      alt={item.currencyPair}
                    />
                    <img
                      className="currency-icon"
                      src={item.pairIcons[1]}
                      alt={item.currencyPair}
                    />
                  </p>
                  {item.currencyPair}
                </div>
                <div className="table-cell price doviz">
                  <Price oldPrice={item.buyPrice.old} newPrice={item.buyPrice.new} />
                </div>
                <div className="table-cell price doviz">
                  <Price
                    oldPrice={item.sellPrice.old}
                    newPrice={item.sellPrice.new}
                  />
                </div>
                <p className="table-cell time">
                  <img
                    src={usetheme ? ClockIconDark : ClockIcon}
                    alt="time"
                    className="clock-icon"
                  />
                  {item.time}
                </p>
              </div>
            ))}
          </div>
        </>
      )}
      {type === "sarrafi" && (
        <>
          <div className="table-heading-container">
            <p className="table-heading sembol">Sembol</p>
            <p className="table-heading price">Yeni Alış</p>
            <p className="table-heading price">Yeni Satış</p>
            <p className="table-heading price">Eski Alış</p>
            <p className="table-heading price">Eski Satış</p>
            <p className="table-heading">
              {`${time.toLocaleDateString("tr")} - ${time.toLocaleTimeString("tr", {
                hour: "2-digit",
                minute: "2-digit",
              })}`}
            </p>
          </div>
          <div className="table-body-container">
            {/*map  */}
            <TableRow2 />
            <TableRow2 />
            <TableRow2 />
            <TableRow2 />
            <TableRow2 />
            <TableRow2 />
            <TableRow2 />
            <TableRow2 />
            <TableRow2 />
            <TableRow2 />
            <TableRow2 />
            <TableRow2 />
          </div>
        </>
      )}
    </div>
  )
}

export default PriceTrackerTable
