import { useState } from "react"
import FacebookIcon from "images/facebook-icon-yellow.svg"
import FacebookIconWhite from "images/facebook-icon-white.svg"
import TwitterIcon from "images/twitter-icon-yellow.svg"
import TwitterIconWhite from "images/twitter-icon-white.svg"
import YouTubeIcon from "images/youtube-icon-yellow.svg"
import YouTubeIconWhite from "images/youtube-icon-white.svg"
import InstagramIcon from "images/instagram-icon-yellow.svg"
import InstagramIconWhite from "images/instagram-icon-white.svg"
import "./SocialIconsBox.css"
const SocialIcon = ({ icon, alternateIcon }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div
      className="social-icon-wrapper"
      onMouseEnter={() => setIsHovered(true)}
      onTouchStart={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onTouchEnd={() => setIsHovered(false)}
    >
      {isHovered && (
        <img className="icon" src={alternateIcon} alt="social media icon" />
      )}
      {!isHovered && <img className="icon" src={icon} alt="social media icon" />}
    </div>
  )
}
const SocialIconsBox = () => {
  return (
    <div className="social-icons-box-container">
      <SocialIcon icon={FacebookIcon} alternateIcon={FacebookIconWhite} />
      <SocialIcon icon={TwitterIcon} alternateIcon={TwitterIconWhite} />
      <SocialIcon icon={YouTubeIcon} alternateIcon={YouTubeIconWhite} />
      <SocialIcon icon={InstagramIcon} alternateIcon={InstagramIconWhite} />
    </div>
  )
}

export default SocialIconsBox
